@import "src/styles/variables";

.container {
    display: flex;
    font-size: 0.875rem;
    padding: 15px 0;
    border-bottom: 1px solid $grey10;
}

.utaColumn {
    line-height: 40px;
    width: 30%;
}

.engineerColumn {
    line-height: 40px;
    width: 50%;
}

.matchColumn {
    width: 20%;
}
