@import "src/styles/variables";

.container {
    background-size: cover;
    background-position: center;
    text-align: center;
    color: $white;

    &.paddingBottom {
        padding-bottom: 100%;
    }

    &.circle {
        border-radius: 100%;
        overflow: hidden;
    }

    &.inlineBlock {
        display: inline-block;
    }
}
