@import "src/styles/variables";

.section {
    margin-bottom: 20px;
}

.title {
    font-weight: $fontWeightBold;
    margin-bottom: 20px;
}

.observation {
    border-radius: 5px;
    border: 1px solid $grey10;
    margin-bottom: 10px;
    padding: 10px;
}
