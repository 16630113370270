@import "src/styles/variables";

.list {
    padding: 20px 0;
    color: $blue;
}

.documentTitle {
    width: 100%;
    transition: background-color 0.4s ease;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background-color: rgba($blue, 0.05);
    }
}
