@import "src/styles/variables";

.container {
    z-index: 1000;
    background-color: $white;
    min-width: 200px;
    box-shadow: $grey10 0 1px 6px, $grey10 0 1px 4px;
    border-radius: 4px;
    max-height: 400px;
    overflow: auto;
}

.padding {
    padding: 10px;
}
