@import "src/styles/variables";

.container {
    font-weight: $fontWeightRegular;
    font-size: 13px;
    width: 210px;

    &.margin {
        margin: 0 10px;
    }
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.headerCell {
    width: 30px;
    padding: 0;
    text-align: center;
}
