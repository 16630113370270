@import "src/styles/variables";

.left {
    display: flex;
    flex-direction: row;

    .tabHeaders {
        width: 200px;
        flex-shrink: 0;
        overflow: auto;
    }

    .tabContent {
        flex-grow: 1;
        border-left: 1px solid $grey10;
    }

    .padding {
        padding: 13px;
    }

    .overflowAuto {
        overflow: auto;
    }
}

.fullHeight {
    height: 100%;
}
