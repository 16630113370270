@import "src/styles/variables";

.container {
    border-bottom: 1px solid $grey10;
}

.header {
    padding: 10px 40px 10px 10px;
    position: relative;
    cursor: pointer;
    color: $blue;
}

.mainContent {
    padding: 10px;

    &.collapsed {
        display: none;
    }
}

.icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.35s ease;

    &.collapsed {
        transform: rotate(180deg) translateY(50%);
    }
}
