@import "src/styles/variables";

.container {
    height: 0.5rem;
    background-color: $grey5;
    border-radius: 0.25rem;
    display: flex;
    overflow: hidden;
}

.progress {
    transition: width 0.6s ease;
}
