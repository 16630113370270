@import "src/styles/variables";

.task {
    padding: 0.3125rem 0.625rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.cursorPointer {
        cursor: pointer;
    }
}

.taskDetails {
    display: flex;
    flex-grow: 1;
    margin-left: 0.625rem;
    margin-right: 1.25rem;
    font-size: 0.875rem;
    align-items: center;
}

.small {
    font-size: 0.75rem;
    color: $grey50;
    flex-shrink: 0;
}
