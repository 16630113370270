@import "src/styles/variables";

.container {
    border: 2px solid $grey10;
    border-radius: 5px;
    margin: 0 10px 10px;
    width: 50%;
}

.reportHeader {
    padding: 0 0.625rem;
    display: flex;
    flex-direction: row;
    min-height: 52px;
    border-bottom: 2px solid $grey10;
}

.reportDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    width: 100%;
}

.reportDetailsAlignedLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
}

.reportTime {
    font-size: 0.75rem;
    display: flex;
    flex-direction: row;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: $grey50;
    padding: 0 0.3125rem;
}

.reportSeparator {
    width: 1.875rem;
    margin: 0 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timelineSeparator {
    margin: 0 0.625rem;
    height: 100%;
    width: 2px;
    background-color: $grey10;
}

.reportDetailsProperty {
    width: 40%;
}

.reportDetailsAlignedRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.failed {
    color: $red;
}
