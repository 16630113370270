@import "src/styles/variables";

.marginTop {
    margin-top: 10px;
}

.tableHeader {
    font-weight: $fontWeightBold;
}

.buttonContainer {
    display: flex;
}
