@import "src/styles/variables";

.message {
    font-size: 1.2rem;
    background-color: $grey70;
    color: $white;
    margin: -13px -13px 0;
    padding: 10px 30px;
}

.messagePara {
    display: inline-block;
    padding-left: 30px;
}

.primaryLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 1rem;
}

.linkContainer {
    min-width: 120px;
    padding: 16px;
    border: 1px solid $grey10;
    margin: 10px;
}

.linkTitle {
    margin-left: 10px;
}

.description {
    text-align: center;
}

.circle {
    background-color: $white;
    border-radius: 100%;
    display: inline-block;
    padding: 5px;
}
