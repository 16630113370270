@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;

    &.hidden {
        opacity: 0;
    }
}
