@import "src/styles/variables";

.lastActive {
    font-size: 12px;
}

.averageJobTime {
    color: $green;
    font-weight: $fontWeightLight;
    font-size: 43px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
