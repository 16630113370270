@import "src/styles/variables";

.container {
    color: $red;
    font-size: 0.75rem;
    margin-top: 5px;

    &.margin {
        margin-bottom: 10px;
    }
}
