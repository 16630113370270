@import "src/styles/variables";

.comment {
    border-bottom: 1px solid $grey10;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.metadata {
    color: $grey50;
    font-size: 14px;
}
