@import "src/styles/variables";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.imagePosition {
    flex-grow: 1;
    position: relative;
}

.imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image {
    display: block;
    margin: 0 auto;
    max-height: 100%;
}

.captionContainer {
    text-align: center;
}

.caption {
    color: $white;
    padding-top: 10px;
    display: inline-block;
    text-align: left;
}

.closeContainer {
    position: fixed;
    top: 20px;
    right: 20px;
}
