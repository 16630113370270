@import "src/styles/variables";

.margin {
    margin-bottom: 10px;
}

.label {
    font-weight: $fontWeightBold;
    font-size: 0.75rem;
    color: $grey50;
    display: block;
    margin-bottom: 5px;
}

.textArea {
    outline: none;
    padding: 5px;
    width: 100%;
    display: block;
    font-size: 1rem;
    border: 1px solid $grey10;
    border-radius: 5px;
    background-color: $white;
    resize: none;
}
