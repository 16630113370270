@import "src/styles/variables";

.container {
    &.active {
        color: $grey50;
    }

    + .container {
        padding-left: 0.5rem;

        &::before {
            float: left;
            padding-right: 0.5rem;
            color: $grey50;
            content: "/";
        }
    }
}
