@import "src/styles/variables";

.table {
    width: 100%;
    border-collapse: collapse;

    tr:nth-child(even) {
        background-color: $grey5;
    }

    td {
        padding: 5px 10px;

        &:first-child {
            width: 200px;
        }
    }
}
