@import "src/styles/variables";

.groupHeader {
    font-size: 1.125rem;
    margin: 10px 0;
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 1px dashed $grey10;
        top: 50%;
        margin-left: 5px;
    }
}
