@import "src/styles/variables";

.diffBackground {
    background-color: $redTransparent;
    margin: -5px;
    padding: 5px;
}

.differenceHighlight {
    background-color: $red;
    color: $white;
    font-style: bold;
    margin: -5px -1px;
    padding: 4px 3px;
}
