@import "src/styles/variables";

.postCommentContainer {
    display: flex;
    flex-direction: row;
}

.inputContainer {
    flex-grow: 1;
    margin-right: 10px;
}
