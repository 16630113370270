@import "src/styles/variables";

.paperworkRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey10;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.documentColumn {
    flex-grow: 1;
}

.selectLabel {
    margin-right: 10px;
}

.percentageColumn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
}
