@import "src/styles/variables";

.section {
    margin-bottom: 30px;
}

.sectionTitle {
    color: $blue;
    font-size: 18px;
    position: relative;
    padding: 6px 0 6px 30px;
    margin-bottom: 4px;

    &.clickable {
        cursor: pointer;
    }

    &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        border: 2px solid $blue;
    }

    &.reviewed::after {
        content: "";
        position: absolute;
        top: 13px;
        left: 7px;
        width: 6px;
        height: 11px;
        border-width: 2px;
        border-style: solid;
        border-color: transparent $blue $blue transparent;
        transform: rotate(45deg);
    }
}
