@import "src/styles/variables";

.filtersHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.moreFiltersContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filtersContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.flexNoShrinkContainer {
    flex-shrink: 0;
}

.flexGrowContainer {
    flex-grow: 1;
}

.propertiesContainer {
    flex-grow: 1;
    overflow: auto;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
}
