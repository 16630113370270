@import "src/styles/variables";

.chart {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 120px;

    .width200 {
        max-width: 200px;
    }

    .width300 {
        max-width: 300px;
    }
}

.innerText {
    position: relative;
}
