@import "src/styles/variables";

.container {
    margin: 2px;
    position: relative;
}

.header {
    width: 100%;
    font-weight: $fontWeightBold;
    white-space: nowrap;
}

.flag {
    border-radius: 100%;
    border: 1px solid $grey100;
    width: 20px;
    height: 20px;
}

.details {
    padding: 5px;
    margin-top: 10px;
    z-index: 10;
    position: absolute;
    max-width: 150px;
    background-color: $white;
    border: 1px solid $grey50;
    border-radius: 10px;
    font-size: 0.75rem;
    overflow-wrap: break-word;
}

.hidden {
    display: none;
}
