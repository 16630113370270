@import "src/styles/variables";

.headerColumn {
    position: sticky;
    left: 0;
    width: 160px;
    z-index: 1;
}

.editColumn {
    position: sticky;
    left: 160px;
    width: 80px;
    z-index: 1;
}
