@import "src/styles/variables";

.banner {
    text-align: center;
    background-color: $red;
    color: $white;
}

.utaRecord {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
}

.utaDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.textWrapper {
    flex-grow: 1;
    margin-right: 5px;
}
