@import "src/styles/variables";

.header {
    padding: 0 5px;
    width: 150px;
    text-align: left;
    background-color: $white;
    vertical-align: bottom;
    position: sticky;
    top: 0;
    z-index: 800;

    &.separator {
        padding: 0 15px;
        border-left: 1px solid $grey10;
    }

    &::before {
        content: "";
        border-bottom: 1px solid $grey10;
        position: absolute;
        inset: 0;
        z-index: -1;
    }
}
