@import "src/styles/variables";

.manualUploadContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.upload {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 5px 7px 10px;
}

.uploadColour {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 0;
    width: 5px;
}

.value {
    font-weight: $fontWeightBold;
    color: $red;
    margin-right: 10px;
    font-size: 0.875rem;
}

.count {
    font-size: 1.2rem;
    font-weight: $fontWeightBold;
    color: $blue;
}

.label {
    font-size: 0.875rem;
    color: $grey50;
}

.chartContainer {
    position: relative;
    width: 200px;
}

.uploadCollapsed {
    padding: 0 5px 0 10px;
}

.chartContainerCollapsed {
    width: 90px;
}

.valueCollapsed {
    font-size: 0.875rem;
}

.total {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateY(-50%) translateX(-50%);
    line-height: 1;
    max-width: 80px;
}

.centerContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
