@import "src/styles/variables";

.container {
    margin-bottom: 10px;
}

.fileUpload {
    padding: 20px;
    border: 4px dashed $blue;
    text-align: center;
    cursor: pointer;
}

.label {
    font-weight: $fontWeightBold;
    font-size: 0.75rem;
    color: $grey50;
    display: block;
    margin-bottom: 5px;
}
