@import "src/styles/variables";

.questionColumn {
    width: 40%;
}

.editColumn {
    width: 160px;
    text-align: center;
}

.actionsColumn {
    width: 100px;
    text-align: center;
}
