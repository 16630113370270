@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid $grey5;
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.imgContainer {
    flex-shrink: 0;
    margin-right: 10px;
}
