@import "src/styles/variables";

.historyContainer {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 9rem;
    margin-bottom: 1rem;
}

.checkbox {
    display: flex;
}
