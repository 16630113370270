@import "src/styles/variables";

.container {
    height: 350px;
    overflow: auto;
}

.option {
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-size: 0.875rem;
    align-items: center;
    border-left: 4px solid transparent;
    padding-left: 11px;

    &.selected {
        border-left-color: $blue;
        background-color: $blue10;
    }
}

.checkbox {
    pointer-events: none;
}

.icon {
    color: $grey70;
}

.label {
    flex-grow: 1;
}

.footer {
    border-top: 1px solid $grey10;
    padding: 5px 20px;
}
