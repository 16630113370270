@import "src/styles/variables";

.table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    th,
    td {
        background-color: $white;
        padding: 10px;
    }

    tbody {
        tr:nth-child(odd) {
            td,
            th {
                background-color: $grey5;
            }
        }

        tr:nth-child(even) {
            td,
            th {
                background-color: $white;
            }
        }
    }

    &.highlightColumn {
        th:nth-child(3),
        td:nth-child(3) {
            background-color: $grey5;
        }

        tbody tr:nth-child(odd) td:nth-child(3) {
            background-color: $grey10;
        }
    }
}

.margin {
    margin-bottom: 30px;
}
