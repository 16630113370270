@import "src/styles/variables";

.issue {
    &:not(:last-child) {
        border-bottom: 1px solid $grey10;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

.issueStatus {
    text-align: center;
    padding: 0 30px;
    float: right;
    margin-left: 5px;
    margin-bottom: 5px;

    &.resolved {
        color: $lightOrange;
        background-color: $lightGreenTransparent;
        border: 1px solid $lightGreen;
    }

    &.unresolved {
        color: $lightOrange;
        background-color: $lightOrangeTransparent;
        border: 1px solid $lightOrange;
    }
}
