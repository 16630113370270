@import "src/styles/variables";

.holding {
    color: $grey50;
    text-align: center;
}

.title {
    margin-bottom: 20px;
    font-size: 2rem;
}
