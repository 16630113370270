@import "src/styles/variables";

.button {
    font-size: 0.875rem;
    font-weight: $fontWeightBold;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid $grey30;
    outline: none;
    color: $grey100;
    display: inline-flex;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba($grey100, 0.05);

    &:hover {
        background-color: darken($white, 10%);
    }

    &:focus {
        box-shadow: 0 0 0 3px rgba($grey100, 0.4);
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.small {
        padding: 2px 6px;
    }

    &.block {
        display: flex;
        width: 100%;
    }

    &.primary {
        border-color: $blue;
        background-color: $blue;
        color: $white;

        &:hover {
            background-color: darken($blue, 10%);
        }

        &:focus {
            box-shadow: 0 0 0 3px rgba($blue, 0.4);
        }
    }

    &.error {
        border-color: $red;
        background-color: $red;
        color: $white;

        &:hover {
            background-color: darken($red, 10%);
        }

        &:focus {
            box-shadow: 0 0 0 3px rgba($red, 0.4);
        }
    }

    &.link {
        padding: 0;
        background-color: transparent;
        border: 0 transparent;
        box-shadow: none;

        &.primary {
            color: $blue;
        }

        &:hover {
            background-color: transparent;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &.white {
        color: $white;
    }
}
