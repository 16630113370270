@import "src/styles/variables";

.count {
    color: $green;
    font-size: 2rem;
    font-weight: $fontWeightBold;
    line-height: 1;
}

.center {
    border-right: 1px solid $grey10;
    text-align: center;
    padding-right: 2px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
