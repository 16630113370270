@import "src/styles/variables";

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 -6px;
}

.th {
    padding: 3px 6px;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
