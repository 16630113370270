@import "src/styles/variables";

.label {
    color: $grey50;
    font-size: 0.875rem;
    line-height: 1;
    margin: 10px 0;
}

.time {
    color: $green;
    font-size: 1.5rem;
    font-weight: $fontWeightBold;
    line-height: 1;
}

.dataContainer {
    text-align: center;
    padding-right: 2px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center {
    border-right: 1px solid $grey10;
    text-align: center;
    padding-right: 2px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.centerContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.count {
    color: $green;
    font-size: 2rem;
    font-weight: $fontWeightBold;
    line-height: 1;
}
