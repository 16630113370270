@import "src/styles/variables";

.container {
    border-radius: 5px;
    border-width: 0 0 0 5px;
    border-style: solid;
    margin-bottom: 10px;
    padding: 16px 16px 16px 11px;
    cursor: pointer;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 40%);
    background-color: $white;

    &.completed {
        border-color: $green;

        .status {
            color: $green;
        }
    }

    &.uta {
        border-color: $purple;

        .status {
            color: $purple;
        }
    }

    &.waiting {
        border-color: $orange;

        .status {
            color: $orange;
        }
    }

    &.missed {
        border-color: $red;

        .status {
            color: $red;
        }
    }
}

.engineer {
    color: $grey50;
    margin-left: 10px;
}

.time {
    color: $grey50;
}

.jobDuration {
    font-size: 0.875em;
    text-align: center;
    color: $white;
    border-radius: 30px;
    margin-left: 5px;
    padding: 0 10px 2px;
    background-color: $blue;

    &.completed {
        background-color: $green;
    }
}

.removeReconcile {
    cursor: pointer;
    background-color: $green;
    color: $white;
    border-radius: 10px;
    padding: 1px 5px;
    font-size: 0.875rem;
}
