@import "src/styles/variables";

.container {
    position: relative;
}

.titleContainer {
    background-image: linear-gradient(
        to left,
        rgba($white, 0),
        rgba($grey100, 0.8)
    );
    border-top: solid 1px $grey10;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    font-weight: 100;
    color: $white;
}

.title {
    font-size: 1.125rem;
    margin-bottom: 3px;
    line-height: 1.1;
}

.subtitle {
    font-size: 12px;
}
