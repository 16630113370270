@import "src/styles/variables";

.container {
    border: 2px solid $grey10;
    border-radius: 5px;
    margin: 0 10px 10px;
}

.jobHeader {
    padding: 0 0.625rem;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid $grey10;
    min-height: 52px;
}

.jobCheckbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0.625rem;
}

.jobTime {
    padding: 0.3125rem 0;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $grey50;
    text-align: center;
}

.jobSeparator {
    width: 1.875rem;
    margin: 0 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: $blue;
    }
}

.jobDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    width: 100%;
}

.jobDetailsAlignedLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    &.compact {
        flex-direction: column;
        align-items: start;
    }
}

.jobDetailsProperty {
    width: 40%;

    &.compact {
        width: 100%;
    }
}

.jobDetailsAlignedRight {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
}

.timelineSeparator {
    margin: 0 0.625rem;
    height: 100%;
    width: 2px;
    background-color: $grey10;
}

.iconFlex {
    display: flex;
    justify-content: center;
}

.subJob {
    background-color: $grey5;
    padding: 0.3125rem 0.625rem;
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
        border-bottom: 1px solid $grey10;
    }
}

.subJobId {
    font-size: 0.75rem;
    display: inline-block;
    padding-right: 45px;
    position: relative;

    &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: $blue;
        position: absolute;
        right: 20px;
        top: 6px;
    }
}

.subJobType {
    margin-right: 10px;
    min-width: 60px;
    display: inline-block;
}
