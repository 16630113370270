@import "src/styles/variables";

.container {
    flex-grow: 1;

    &.margin {
        margin-bottom: 10px;
    }
}

.input {
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 8px;
    font-size: 0.875rem;
    background-color: transparent;
    flex-grow: 1;
    width: 0;

    &.small {
        padding: 2px;
    }

    &:focus {
        box-shadow: 0 0 0 3px rgba($grey100, 0.4);
    }
}

.label {
    font-weight: $fontWeightBold;
    font-size: 0.75rem;
    color: $grey50;
    display: block;
    margin-bottom: 5px;
}

.border {
    display: flex;
    flex-direction: row;
    border: 1px solid $grey10;
    border-radius: 5px;
    background-color: $white;
    align-items: center;
}
