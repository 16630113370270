@import "src/styles/variables";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $grey3;
}

.content {
    overflow: auto;
    padding: 10px;
    width: 500px;
}
