@import "src/styles/variables";

.loading {
    width: 240px;
    margin: 0 10px;
    text-align: center;
}

.button {
    border: 1px solid $grey10;
    background-color: $white;
    border-radius: 4px;
    width: 240px;
    margin: 0 10px;
    padding: 9px 30px 9px 20px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-size: 0.875rem;
    align-items: center;
}

.icon {
    color: $grey70;
}

.chevronIcon {
    position: absolute;
    right: 2px;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
