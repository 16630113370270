@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
}

.navigation {
    width: 350px;
}

.navigationComponents {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.accordionContainer {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
}

.documentViewer {
    flex: 1;
    background-color: $grey50;
}

.pdfViewer {
    display: block;
    width: 100%;
    height: 100%;
}

.otherViewer {
    display: block;
    object-fit: cover;
}

.auditSummary {
    border-top: 1px solid $grey10;
    padding: 5px 20px;
}

.outstandingActions {
    padding-bottom: 5px;
}

.actionItem {
    font-size: 12px;
}

.auditCommands {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    margin: 0 30px;

    button {
        margin: 0 5px;
        flex: 2;
    }
}

.warningList {
    padding-left: 15px;
}

.warningAlert {
    animation: pulsate 0.5s ease-out;
    animation-iteration-count: 1;
    opacity: 1;
}

.warning2Alert {
    animation: pulsate 0.5s ease-out;
    animation-iteration-count: 1;
    opacity: 1;
}

@keyframes pulsate {
    1% {
        color: $red;
        opacity: 0.8;
    }

    100% {
        color: $grey100;
    }
}
