@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.predefinedOptions {
    flex-shrink: 0;
    width: 165px;
}
