@import "src/styles/variables";

.buttonsContainer {
    display: flex;
    padding-bottom: 5px;
}

.generatingContainer {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    width: 125px;
}
