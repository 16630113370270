@import "src/styles/variables";

.container {
    display: flex;
    font-size: 0.875rem;
    padding: 15px 0;
    border-bottom: 1px solid $grey10;
}

.jobColumn {
    line-height: 40px;
    width: 12%;
}

.dateColumn {
    line-height: 40px;
    width: 27%;
}

.taskColumn {
    line-height: 40px;
    width: 46%;
}

.matchColumn {
    width: 20%;
}
