@import "src/styles/variables";

.bannerLink {
    display: block;
    margin-bottom: 26px;
}

.bannerImage {
    display: block;
}
