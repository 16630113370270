@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.menu {
    flex-grow: 1;
    overflow: auto;
}

.carousel {
    padding: 10px;
}
