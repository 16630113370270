@import "src/styles/variables";

.container {
    display: inline-block;
    margin: 0 1px;
    border-width: 1px;
    border-radius: 2px;
    border-style: solid;
    border-color: transparent;

    &.current {
        border-color: $white;
    }
}
