@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.total {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateY(-50%) translateX(-50%);
    line-height: 1;
    max-width: 80px;
}

.label {
    font-size: 0.875rem;
    color: $grey50;
}

.count {
    font-size: 1.2rem;
    font-weight: $fontWeightBold;
    color: $blue;
}

.condition {
    position: relative;
    padding: 5px 5px 7px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.collapsed {
        padding: 0 5px 0 10px;
    }
}

.leftBar {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 0;
    width: 5px;
}

.chartContainer {
    width: 200px;
    position: relative;

    &.collapsed {
        width: 90px;
    }
}

.value {
    font-size: 1.5rem;
    font-weight: $fontWeightBold;
    margin-right: 10px;

    &.collapsed {
        font-size: 0.875rem;
    }
}

.centerContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
