@import "src/styles/variables";

.container {
    position: fixed;
    bottom: 10px;
    width: 300px;
    left: 50%;
    margin-left: -150px;
    padding: 10px;
    color: $white;
    background-color: $grey70;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 50%);
    border-radius: 2px;
}
