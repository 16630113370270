@import "src/styles/variables";

.container {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.totalResults {
    font-size: 14px;
    margin: 0 10px;
}

.columns {
    padding-top: 10px;
}

.action {
    display: flex;
    flex-direction: row;
}

.actionText {
    padding: 0 5px;
    flex-grow: 1;
}
