@import "src/styles/variables";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    flex-shrink: 0;
}
