@import "src/styles/variables";

.flex {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.comment {
    margin-top: 5px;
}
