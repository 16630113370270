/* stylelint-disable */

@font-face {
    font-family: "icons";
    src: url("fonts/icons.ttf?ryk0xy") format("truetype"),
        url("fonts/icons.woff?ryk0xy") format("woff"),
        url("fonts/icons.svg?ryk0xy#icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.check::before {
    content: "\e912";
}

.details::before {
    content: "\e900";
}

.alarms::before {
    content: "\e901";
}

.gas_supply::before {
    content: "\e902";
}

.bell-filled::before {
    content: "\e903";
}

.bell::before {
    content: "\e907";
}

.survey::before {
    content: "\e908";
}

.solar::before {
    content: "\e909";
}

.comment::before {
    content: "\e90a";
}

.solid_fuel::before {
    content: "\e90b";
}

.fire::before {
    content: "\e90c";
}

.calendar::before {
    content: "\e90d";
}

.menu::before {
    content: "\e90f";
}

.more::before {
    content: "\e910";
}

.pencil::before {
    content: "\e913";
}

.bolt::before {
    content: "\e914";
}

.electric::before {
    content: "\e914";
}

.contacts::before {
    content: "\e915";
}

.notes::before {
    content: "\e916";
}

.service::before {
    content: "\e917";
}

.search::before {
    content: "\e919";
}

.circuits::before {
    content: "\e91a";
}

.electric_communal::before {
    content: "\e91b";
}

.electric_dwelling::before {
    content: "\e91c";
}

.remove::before {
    content: "\e91d";
}

.gear::before {
    content: "\e91e";
}

.bar-chart::before {
    content: "\e91f";
}

.area-chart::before {
    content: "\e920";
}

.pie-chart::before {
    content: "\e924";
}

.list::before {
    content: "\e925";
}

.final_checks::before {
    content: "\e925";
}

.buildings::before {
    content: "\e927";
}

.not_tested::before {
    content: "\e928";
}

.register_alarm::before {
    content: "\e92b";
}

.register_appliance::before {
    content: "\e92b";
}

.plus::before {
    content: "\e92b";
}

.close::before {
    content: "\e92e";
}

.chevron-down::before {
    content: "\e92f";
}

.chevron-left::before {
    content: "\e930";
}

.chevron-right::before {
    content: "\e931";
}

.chevron-up::before {
    content: "\e934";
}

.info::before {
    content: "\e937";
}

.eye::before {
    content: "\e939";
}

.visual_inspection::before {
    content: "\e939";
}

.download::before {
    content: "\e93a";
}

.install_appliance::before {
    content: "\e93a";
}

.upload::before {
    content: "\e93b";
}

.lock::before {
    content: "\e93c";
}

.uta_only::before {
    content: "\e93c";
}

.caret-left::before {
    content: "\e905";
}

.caret-right::before {
    content: "\e906";
}

.caret-up::before {
    content: "\e911";
}

.caret-down::before {
    content: "\e904";
}

.legionella::before {
    content: "\e938";
}

.multi::before {
    content: "\e936";
}

.property_services::before {
    content: "\e935";
}

.manual-upload::before {
    content: "\e932";
}

.manual-upload-uta::before {
    content: "\e933";
}

.fire_door::before {
    content: "\e921";
}

.four-squares::before {
    content: "\e90e";
}

.all::before {
    content: "\e90e";
}

.unvented::before {
    content: "\e929";
}

.ashp::before {
    content: "\e92a";
}

.communal::before {
    content: "\e92c";
}

.commercial::before {
    content: "\e92d";
}

.door::before {
    content: "\e923";
}

.oil::before {
    content: "\e926";
}

.gas::before {
    content: "\e922";
}

.appliance_different::before {
    content: "\f021";
}

.test_alarm::before {
    content: "\f0a6";
}

.repair::before {
    content: "\f0fa";
}

.send::before {
    content: "\f1d8";
}

.uta-details::before {
    content: "\e93f";
}

.tag-details::before {
    content: "\e943";
}
