@import "src/styles/variables";

.issue {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
    padding: 5px;
    margin-bottom: 10px;
    border: 2px solid $grey5;
    justify-content: space-between;
}

.dateWrapper {
    min-width: 80px;
    margin-left: 5px;
    flex-shrink: 0;
    color: $grey50;
    text-align: right;
}
