@import "src/styles/variables";

.body {
    overflow: auto;
    flex-grow: 1;

    &.padding {
        padding: 13px;
    }
}
