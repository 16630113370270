@import "src/styles/variables";

.flag {
    display: inline-block;
    margin-bottom: 10px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 2px solid $grey70;

    &:not(:nth-child(8n + 8)) {
        margin-right: 10px;
    }
}
