@import "src/styles/variables";

.table {
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;
}

.row {
    border-bottom: 1px solid $grey10;

    &:last-child {
        border-bottom: 0;
    }
}

.bold {
    font-weight: $fontWeightBold;
}
