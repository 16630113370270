@import "src/styles/variables";

.margin {
    margin-bottom: 10px;
}

.inline {
    display: inline-block;
}

.checkbox {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid $grey10;
    background-color: $grey3;
    position: relative;
    display: inline-block;
    color: $white;
    vertical-align: sub;
}

.checked {
    background-color: $blue;
    border-color: $blue;
}

.checkedIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
