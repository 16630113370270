@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    border-top: 2px solid $grey10;
    height: 100%;
}

.sidebar {
    width: 300px;
    flex-shrink: 0;
    border-right: 2px solid $grey10;
    height: 100%;
    overflow: auto;
}

.mainContent {
    flex-grow: 1;
    padding: 10px;
    overflow: auto;
}

.sectionSubtitle {
    font-weight: $fontWeightBold;
    margin-bottom: 20px;
}

.error {
    padding-right: 10px;
    display: inline-block;
}
