@import "src/styles/variables";

.container {
    overflow-wrap: break-word;
    padding: 5px;

    &.separator {
        padding: 5px 15px;
        border-left: 1px solid $grey10;
    }
}
