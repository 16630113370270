@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    flex-shrink: 0;
    z-index: 200;
    box-shadow: 0 1px 2px 0 rgba($grey30, 0.3);
}

.logoContainer {
    width: 220px;
    padding-left: 20px;
}

.logo {
    width: 130px;
    display: block;
}

.middle {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.actions {
    padding-right: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.beamer {
    display: flex;
}
