@import "src/styles/variables";

.container {
    display: inline-block;
    padding: 2px 5px;
    color: $white;
    font-weight: $fontWeightBold;
    border-radius: 2px;
    margin-right: 3px;
    margin-bottom: 3px;
    font-size: 12px;
    background-color: $grey100;
}

.pillRemove {
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    top: -1px;
}
