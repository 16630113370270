@import "src/styles/variables";

.time {
    color: $green;
    font-size: 2.5rem;
    font-weight: $fontWeightBold;
    line-height: 1;
}

.centerContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
