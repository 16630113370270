@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.status {
    position: relative;
    padding: 5px 5px 7px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.statusColour {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 0;
    width: 5px;
}

.label {
    font-size: 0.875rem;
    color: $grey50;
}

.value {
    font-size: 1.5rem;
    font-weight: $fontWeightBold;
    margin-right: 10px;
}

.subText {
    padding-top: 5px;
    text-align: center;
    line-height: 1.1;
}

.total {
    position: absolute;
    top: 45px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    line-height: 1.1;
    max-width: 80px;
}

.count {
    font-size: 1.2rem;
    font-weight: $fontWeightBold;
    color: $blue;
    margin-bottom: 5px;
}

.chartContainer {
    width: 150px;
    position: relative;
}

.centerContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
