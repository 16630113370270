@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
}

.leftColumn {
    width: 50px;
    margin-right: 10px;
    flex-shrink: 0;
}

.rightColumn {
    flex-grow: 1;
}

.legend {
    font-size: 1.2rem;
    font-weight: $fontWeightBold;
    line-height: 1;
    margin-bottom: 10px;
}

.label {
    color: $grey50;
    font-size: 0.8rem;
    font-weight: $fontWeightRegular;
}

.subText {
    padding-top: 5px;
    text-align: center;
    line-height: 1.1;
}
