// Color Palette https://plentific.atlassian.net/wiki/spaces/DT/pages/5317525517/Front-End

$blue: #0657a7;
$blue10: #e6eef6;
$grey100: #061421;
$grey70: #4f5a64;
$grey50: #6d747b;
$grey30: #b3b8bc;
$grey10: #e6e8e9;
$grey5: #f3f3f4;
$grey3: #f9f9f9;
$white: #fff;

// Fonts

$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightBold: 600;

@font-face {
    font-family: AvenirNext;
    src: url("fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
    src: url("fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix")
            format("eot"),
        url("fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),
        url("fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"),
        url("fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype"),
        url("fonts/52a192b1-bea5-4b48-879f-107f009b666f.svg#52a192b1-bea5-4b48-879f-107f009b666f")
            format("svg");
    font-weight: $fontWeightLight;
}

@font-face {
    font-family: AvenirNext;
    font-weight: $fontWeightRegular;
    src: url("fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix");
    src: url("fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix")
            format("eot"),
        url("fonts/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"),
        url("fonts/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff"),
        url("fonts/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf") format("truetype"),
        url("fonts/a89d6ad1-a04f-4a8f-b140-e55478dbea80.svg#a89d6ad1-a04f-4a8f-b140-e55478dbea80")
            format("svg");
}

@font-face {
    font-family: AvenirNext;
    font-weight: $fontWeightBold;
    src: url("fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
    src: url("fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix")
            format("eot"),
        url("fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),
        url("fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"),
        url("fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype"),
        url("fonts/99affa9a-a5e9-4559-bd07-20cf0071852d.svg#99affa9a-a5e9-4559-bd07-20cf0071852d")
            format("svg");
}

// Old variables

$paleBlue: #e5f5ff;
$lightBlue: #00b4db;
$lightBlueTransparent: rgba(#34a9ff, 0.5);
$lightGreen: #7ed321;
$lightGreenTransparent: rgba($lightGreen, 0.05);
$green: #068408;
$red: #d0021b;
$redTransparent: rgba($red, 0.05);
$lightOrange: #f5a623;
$lightOrangeTransparent: rgba($lightOrange, 0.05);
$orange: #e86819;
$purple: #3b59b2;
