@import "src/styles/variables";

@keyframes slideshow {
    0%,
    100% {
        margin-left: 550px;
        opacity: 0;
        visibility: hidden;
    }

    10%,
    90% {
        margin-left: -10px;
        opacity: 1;
        visibility: visible;
    }
}

.popup {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 20%);
    margin-bottom: 5px;
    animation: slideshow 5.6s forwards;
    animation-timing-function: cubic-bezier(0.56, 0.015, 0.34, 0.955);
    cursor: pointer;
}

.icon {
    margin-right: 10px;
}

.details {
    flex-grow: 1;
}

.title {
    margin-bottom: 2px;
    font-size: 0.875rem;
    width: 100%;
}

.description {
    font-weight: $fontWeightLight;
    font-size: 0.75rem;
    color: $grey50;
    width: 100%;
}
