@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
}

.leftColumn {
    flex-grow: 1;
    max-width: 110px;
    margin-right: 10px;
}

.rightColumn {
    flex-grow: 1;
}

.actions {
    display: flex;
    flex-direction: row;
}

.value {
    color: $green;
    font-size: 1.8rem;
    font-weight: $fontWeightBold;
}

.label {
    color: $grey50;
    font-size: 0.875rem;
}
