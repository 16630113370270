@import "src/styles/variables";

.filters {
    text-align: left;
}

.filter {
    color: $blue;
    font-size: 0.75rem;
    font-weight: $fontWeightBold;

    &:not(:last-child) {
        padding-right: 4px;
        margin-right: 4px;
        border-right: 2px solid $lightBlue;
    }
}
