@import "src/styles/variables";

.unableToAccess {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
    padding: 5px;
    margin-bottom: 10px;
    border: 2px solid $grey5;
}

.imageWrapper {
    margin-right: 20px;
    flex-shrink: 0;
}

.textWrapper {
    flex-grow: 1;
    margin-right: 5px;
}
