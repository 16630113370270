@import "src/styles/variables";

.canvas {
    margin: 0 auto;
    display: block;
    border: 1px solid $grey100;
    height: 400px;
    width: 400px;
}

.container {
    margin-bottom: 10px;
}

.image {
    margin: 0 auto;
    display: block;
}
