@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
}

.label {
    color: $grey50;
    font-size: 0.875rem;
    line-height: 1;
    margin: 10px;
}
