@import "src/styles/variables";

.observationsNotice {
    margin-left: 15px;
}

.observationsNoticeRow {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}
