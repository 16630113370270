@import "src/styles/variables";

.changeStatus {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0;
    border: 2px solid $grey10;
    border-radius: 4px;
}

.status {
    display: flex;
    flex-direction: row;
    padding: 10px 24px;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;

    &.resolved {
        color: $green;
        border: 2px solid $green;
    }

    &.unresolved {
        color: $orange;
        border: 2px solid $orange;
    }

    &.blocked {
        color: $red;
        border: 2px solid $red;
    }
}

.buttons {
    flex-shrink: 0;
}

.leftContainer {
    flex-grow: 1;
    margin-right: 10px;
}
