@import "src/styles/variables";

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    padding: 0;
    font-family: AvenirNext, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    background-color: $white;
    color: $grey100;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol {
    margin-bottom: 0;
}

ol ul {
    margin-bottom: 0;
}

ul ol {
    margin-bottom: 0;
}

ul ul {
    margin-bottom: 0;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
