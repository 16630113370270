@import "src/styles/variables";

.row {
    background-color: $grey5;
    padding: 13px;
    margin: -13px -13px 0;
}

.actions {
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
}
