@import "src/styles/variables";

.container {
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 20%);
    overflow: hidden;
    margin-bottom: 26px;
}

.title {
    color: $white;
    padding: 5px;
    text-align: center;
}

.value {
    font-size: 2rem;
    text-align: center;
}
