@import "src/styles/variables";

.container {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;

    &.margin {
        margin: 0 -13px;
    }

    &.fullHeight {
        height: 100%;
    }

    &.alignCenter {
        justify-content: center;
    }

    &.alignCenterVertical {
        align-items: center;
    }
}
