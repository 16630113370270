@import "src/styles/variables";

.container {
    overflow: auto;
    margin-bottom: 30px;

    tbody td {
        vertical-align: top;
    }
}

.headerColumn {
    position: sticky;
    left: 0;
    width: 160px;
    z-index: 1;
    vertical-align: bottom;
}

.editColumn {
    position: sticky;
    left: 160px;
    width: 80px;
    z-index: 1;
}

.column {
    width: 85px;
    vertical-align: bottom;
}

.rotatedColumn {
    width: 85px;
    height: 200px;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    vertical-align: top;
}
