@import "src/styles/variables";

.matchesTitle {
    color: $blue;
}

.typeHeader {
    font-weight: $fontWeightBold;
    margin-top: 10px;
}
