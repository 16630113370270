@import "src/styles/variables";

.propertyDropdownItem {
    padding: 8px;
    border-left-width: 5px;
    border-left-style: solid;
    border-bottom: 1px solid $grey10;
    max-width: 560px;

    &.green {
        border-left-color: $green;
    }

    &.orange {
        border-left-color: $orange;
    }

    &.red {
        border-left-color: $red;
    }
}

.propertyCategory {
    margin: 10px 5px;
    text-align: center;
}

.dueDate {
    &.green {
        color: $green;
    }

    &.orange {
        color: $orange;
    }

    &.red {
        color: $red;
    }
}

.complianceTypeDropdownItem {
    min-width: 380px;
}

.warningMessage {
    font-size: 0.775rem;
    color: $red;
}
