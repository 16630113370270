@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid $grey10;
}

.containerLeft {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-right: 10px;
}

.containerRight {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
}

.notificationBody {
    padding: 8px;
}

.notificationTitle {
    font-size: 0.9375rem;
    font-weight: $fontWeightBold;
}

.notificationDescription {
    font-size: 0.8125rem;
    color: $grey50;
}

.showPopup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
