.container {
    display: flex;
    align-items: row;
}

.side {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
}
