@import "src/styles/variables";

.filterGroup:not(:last-child) {
    padding-bottom: 0.625rem;
    margin-bottom: 0.625rem;
    border-bottom: 1px solid $grey10;
}

.filterGroupTitle {
    color: $blue;
    margin-bottom: 0;
}
