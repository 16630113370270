@import "src/styles/variables";

.container {
    height: calc(100% - 75px);
    padding: 10px;
}

.image {
    max-height: 100%;
    display: block;
    margin: 0 auto;
}

.closeContainer {
    position: fixed;
    top: 0;
    right: 0;
}

.caption {
    position: fixed;
    top: 5px;
    left: 5px;
    color: $white;
}

.thumbnails {
    overflow: auto;
    white-space: nowrap;
    padding: 0 5px;
    text-align: center;
}

.arrowContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }
}
