.row {
    padding: 10px 0;
}

.actions {
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
}
