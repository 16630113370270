@import "src/styles/variables";

.backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 70%);
    z-index: 1000;
    padding: 20px;

    &.alignCenter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
