@import "src/styles/variables";

.container {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
}

.editOption {
    margin-top: 10px;
    border-top: 1px solid $grey10;
    padding: 10px;
}
