@import "src/styles/variables";

.header {
    width: 100%;
    text-align: left;
    padding: 10px;
    transition: background-color 0.4s ease;
    color: $white;
    background-color: $grey50;

    &:hover {
        background-color: darken($grey50, 3);
    }

    &::after {
        content: "Awaiting";
        position: absolute;
        right: 40px;
        top: 7px;
        padding: 3px 0;
        width: 80px;
        text-align: right;
    }
}

.pass {
    background-color: $green;
    color: $white;

    &:hover {
        background-color: darken($green, 3);
    }

    &::after {
        content: "Pass";
    }
}

.fail {
    background-color: $red;
    color: $white;

    &:hover {
        background-color: darken($red, 3);
    }

    &::after {
        content: "Failed";
    }
}

.chevron {
    position: absolute;
    top: 7px;
    right: 0;
    transition: transform 0.6s ease;
}

.expanded {
    transform: rotate(-180deg);
}

.active {
    width: 100%;
}
