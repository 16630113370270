@import "src/styles/variables";

@keyframes keyFrame {
    25%,
    100% {
        height: 100%;
        color: $white;
    }
}

.container {
    padding: 4px;
    height: 100%;
    overflow: hidden;
    margin-bottom: 20px;

    &:hover {
        background-color: $grey5;
    }
}

.animated {
    inset: 0;
    background-color: $lightGreen;
    width: 100%;
    height: 0;
    color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: keyFrame 1s ease 0.5s 2 alternate;
    border-radius: 10px;
}
