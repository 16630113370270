@import "src/styles/variables";

.accordionSection {
    display: flex;
    flex-direction: column;
}

.accordionItem {
    border-bottom: 1px solid $grey10;
    position: relative;
}

.accordionHeader {
    color: $blue;
    display: inline-flex;
    min-height: 30px;
    background-color: $white;
    outline: none;
    border: none;
    width: 100%;
    text-align: left;
    padding: 0;
    transition: background-color 0.4s ease;
    cursor: pointer;

    &:hover {
        background-color: rgba($blue, 0.05);
    }
}

.plainTextHeader {
    padding: 10px;
    flex: 1;
}

.sectionHeading {
    padding: 20px 10px 10px;
    color: $grey70;
}

.activeContent {
    text-decoration: underline;
}

.expandButton {
    margin-left: auto;
}

.accordionContent {
    transition: max-height 0.4s ease;
    overflow: hidden;
    height: auto;
}

.accordionItemContent {
    padding: 10px;
    overflow: hidden;
}

.expanded {
    height: auto;
}

.collapsed {
    max-height: 0;
}

.chevron {
    margin-top: 7px;
    margin-left: auto;
    transition: transform 0.6s ease;
    overflow: hidden;
}

.rotate {
    transform: rotate(-180deg);
    overflow: hidden;
}
