@import "src/styles/variables";

.wrapper {
    z-index: 500;
    position: fixed;
    width: 300px;
    font-size: 0;

    &.top {
        text-align: center;
        height: 100px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
    }

    &.bottom {
        text-align: center;
    }

    &.right {
        height: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &.left {
        height: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}

.container {
    background-color: rgba($grey100, 0.8);
    color: $white;
    padding: 8px;
    line-height: 1;
    border-radius: 2px;
    display: inline-block;
    text-align: left;
    box-shadow: 0 2px 7px rgba($grey100, 0.3);
    font-size: 14px;
}
