@import "src/styles/variables";

.sideItem {
    color: $white;
    background-color: $grey50;
    padding: 8px 40px 8px 10px;
    border-bottom: 1px solid $grey10;
    position: relative;
    cursor: pointer;

    &.paddingLeft {
        padding-left: 30px;
    }

    &:not(.collapse) {
        &::before {
            content: "";
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 5px;
            width: 20px;
            height: 20px;
            border: 2px solid $white;
        }

        &.reviewed {
            background-color: $blue;

            &::after {
                content: "";
                position: absolute;
                top: 13px;
                right: 17px;
                width: 6px;
                height: 11px;
                border-width: 2px;
                border-style: solid;
                border-color: transparent $white $white transparent;
                transform: rotate(45deg);
            }
        }
    }
}

.icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.35s ease;

    &.collapsed {
        transform: rotate(180deg) translateY(50%);
    }
}
