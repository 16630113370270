@import "src/styles/variables";

.container {
    padding: 4px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    &:hover {
        background-color: $grey5;
    }
}

.imageContainer {
    flex-shrink: 0;
    flex-grow: 0;
}

.linkContainer {
    margin-left: 5px;
    flex-grow: 1;
}
