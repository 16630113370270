@import "src/styles/variables";

.container {
    border-bottom: 1px solid $grey10;
}

.field {
    padding: 14px;
    font-size: 0.75rem;
    overflow: hidden;
}

.multiLine {
    white-space: pre;
}
