@import "src/styles/variables";

.rightContainer {
    padding-left: 10px;
    border-left: 1px solid $grey10;
    height: 100%;
}

.addOption {
    border-top: 1px solid $grey10;
    margin-top: 10px;
    padding: 10px;
}
