@import "src/styles/variables";

.attemptLabel {
    color: $grey50;
    font-size: 0.875rem;
}

.firstAttempt {
    color: $green;
    font-size: 2.5rem;
    font-weight: $fontWeightBold;
    line-height: 1;
}

.secondAttempt {
    color: $blue;
}

.thirdAttempt {
    color: $orange;
}

.fourthAttempt {
    color: $red;
}

.center {
    border-right: 1px solid $grey10;
    text-align: center;
    padding-right: 2px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.accessRate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.75rem;
    color: $grey50;
    margin-bottom: 10px;
}

.percentageWrapper {
    width: 70%;
}

@keyframes animateWidth {
    from {
        width: 0;
    }
}

.percentage {
    background-color: $lightBlue;
    border-radius: 4px;
    animation: animateWidth 1.5s ease;
    text-align: center;
    color: $white;
    height: 100%;

    .text {
        white-space: nowrap;
    }

    &.small {
        position: relative;

        .text {
            position: absolute;
            left: 100%;
            color: $grey100;
            margin-left: 2px;
        }
    }
}

.centerContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.bold {
    font-weight: $fontWeightBold;
}
