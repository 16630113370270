@import "src/styles/variables";

.container {
    background-color: $white;
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    width: 600px;

    &.fullWidth {
        width: 100%;
    }

    &.fullHeight {
        height: 100%;
    }
}

.header {
    padding: 13px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
}

.title {
    flex-grow: 1;
}

.body {
    overflow: auto;
    flex-grow: 1;

    &.padding {
        padding: 13px;
    }
}
