@import "src/styles/variables";

.phase {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    display: block;
    margin: 1px;
    border: 2px solid $grey70;

    &.inline {
        display: inline-block;
        margin: 0 5px 5px 0;
    }

    &.editing {
        cursor: pointer;
    }
}
