@import "src/styles/variables";

.message {
    margin-top: 1rem;
    font-weight: $fontWeightBold;
    font-size: 13px;
    min-height: 4rem;
}

.footer {
    padding: 10px;
    text-align: right;
    flex-shrink: 0;
}
