@import "src/styles/variables";

.margin {
    margin-bottom: 10px;
}

.label {
    font-weight: $fontWeightBold;
    font-size: 0.75rem;
    color: $grey50;
    display: block;
    margin-bottom: 5px;
}

.border {
    display: flex;
    flex-direction: row;
    border: 1px solid $grey10;
    border-radius: 5px;
    background-color: $white;
    align-items: center;
    position: relative;
}

.option {
    flex-grow: 1;
}

.icon {
    position: absolute;
    right: 0.3125rem;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.clickable {
    cursor: pointer;
}

.noPointerEvents {
    pointer-events: none;
}

.select {
    overflow: hidden;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0 30px 0 10px;
    background-color: transparent;
    appearance: none;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $blue;
    position: relative;
    min-height: 37px;
    font-size: 0.875rem;

    &.small {
        min-height: 34px;
    }

    &:focus {
        box-shadow: 0 0 0 3px rgba($grey100, 0.4);
    }
}
