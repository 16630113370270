@import "src/styles/variables";

.link {
    color: $blue;
    text-decoration: none;
    outline: none;

    &.red {
        color: $red;
    }

    &.black {
        color: $grey100;
    }

    &.green {
        color: $green;
    }

    &.orange {
        color: $orange;
    }

    &.grey {
        color: $grey50;
    }

    &.darkGrey {
        color: $grey70;
    }
}

.bordered {
    cursor: pointer;
    border: 1px solid $lightBlue;
    border-radius: 2px;
    padding: 3px;
    margin-left: 5px;
}

.block {
    display: block;

    > .link {
        display: block;
    }
}

.textCenter > .link {
    text-align: center;
}
