@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;

    &:hover {
        background-color: $grey10;
    }
}

.name {
    flex-grow: 1;
}

.icon {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: block;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;
}
