@import "src/styles/variables";

.compliance {
    border-radius: 2px;
    padding: 2px 5px 1px;
    color: $white;
    text-align: center;
    flex-shrink: 0;

    &.inline {
        display: inline-block;
    }
}
