@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.mapWrapper {
    width: 30%;
    position: relative;
    flex-shrink: 0;
}

.propertyImage {
    position: absolute;
    bottom: 30px;
    left: 6px;
    height: 100px;
    width: 100px;
    border: 1px solid $blue;
    border-radius: 5px;
}

.propertyDetails {
    padding: 13px;
    flex-grow: 1;
}

.title {
    position: relative;
    font-size: 20px;
    padding-right: 50px;
}

.editButton {
    position: absolute;
    top: 0;
    right: 0;
}
