@import "src/styles/variables";

.margin {
    margin-bottom: 10px;
}

.strikeThrough {
    text-decoration: line-through;
}

.relative {
    position: relative;
}
