@import "src/styles/variables";

.tableScroll {
    overflow: auto;
    min-height: 200px;
    max-height: 57vh;
}

.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.gridWrapper {
    padding: 0 13px;
}

.row {
    background-color: $white;

    &.even {
        background-color: $grey5;
    }
}

.bulkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
