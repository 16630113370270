@import "src/styles/variables";

.messageScrollView {
    max-height: 300px;
    overflow: auto;
    margin-bottom: 10px;
}

.messageLogWrapper {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
}

.commentHeader {
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
}

.commentHeaderRight {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
}

.commentHeaderRightStatus {
    display: flex;
    flex-direction: row;
}

.imageContainer {
    flex-shrink: 0;
}

.username {
    font-weight: $fontWeightBold;
    margin: 0 10px;
    flex-grow: 1;
}

.commentDate {
    color: $grey50;
    font-size: 0.875em;
}

.comment {
    border-bottom: 1px solid $grey10;
    margin-bottom: 8px;
    flex-grow: 1;
}
