@use "sass:math";

.container {
    padding: 0 13px;
    flex: 1 0 100%;

    @media (min-width: 1200px) {
        &.fullHeight {
            height: 100%;
        }

        &.half {
            flex: 1 0 (math.div(1, 2) * 100%);
            max-width: math.div(1, 2) * 100%;
        }

        &.oneThird {
            flex: 1 0 (math.div(1, 3) * 100%);
            max-width: math.div(1, 3) * 100%;
        }

        &.twoThirds {
            flex: 1 0 (math.div(2, 3) * 100%);
            max-width: math.div(2, 3) * 100%;
        }

        &.oneQuarter {
            flex: 1 0 (math.div(1, 4) * 100%);
            max-width: math.div(1, 4) * 100%;
        }

        &.fiveOverTwelve {
            flex: 1 0 (math.div(5, 12) * 100%);
            max-width: math.div(5, 12) * 100%;
        }

        &.sevenOverTwelve {
            flex: 1 0 (math.div(7, 12) * 100%);
            max-width: math.div(7, 12) * 100%;
        }

        &.threeQuarters {
            flex: 1 0 (math.div(3, 4) * 100%);
            max-width: math.div(3, 4) * 100%;
        }

        &.tenPercent {
            flex: 1 0 (math.div(1, 10) * 100%);
            max-width: math.div(1, 10) * 100%;
        }

        &.twentyPercent {
            flex: 1 0 (math.div(2, 10) * 100%);
            max-width: math.div(2, 10) * 100%;
        }

        &.thirtyPercent {
            flex: 1 0 (math.div(3, 10) * 100%);
            max-width: math.div(3, 10) * 100%;
        }

        &.seventyPercent {
            flex: 1 0 (math.div(7, 10) * 100%);
            max-width: math.div(7, 10) * 100%;
        }

        &.eightyPercent {
            flex: 1 0 (math.div(8, 10) * 100%);
            max-width: math.div(8, 10) * 100%;
        }

        &.ninetyPercent {
            flex: 1 0 (math.div(9, 10) * 100%);
            max-width: math.div(9, 10) * 100%;
        }
    }
}
