@import "src/styles/variables";

.container {
    display: flex;
    flex-direction: row;
}

.topRow {
    border-bottom: 1px solid $grey10;
}

.leftColumn {
    width: 120px;
    margin-right: 10px;
    flex-shrink: 0;
}

.rightColumn {
    flex-grow: 1;
}

.chartLabel {
    margin-top: 5px;
    color: $grey50;
    font-size: 0.875rem;
    text-align: center;
}

.capped {
    text-align: center;
    justify-content: center;
}

.cappedLabel {
    color: $grey50;
    font-size: 0.775rem;
    font-weight: $fontWeightBold;
}

.cappedValueLink {
    display: inline-block;
    font-size: 2rem;
    width: 100%;
    font-weight: $fontWeightBold;
    vertical-align: middle;
    margin: 15px 15px 0 0;
}

.cappedValue {
    font-size: 1.2rem;
    text-align: center;
    font-weight: $fontWeightBold;
    margin-left: 5px;
}

.value {
    font-size: 1.8rem;
    font-weight: $fontWeightBold;
    text-align: center;
    margin-right: 0;
}

.label {
    color: $grey50;
    font-size: 0.675rem;
    font-weight: $fontWeightBold;
    text-align: center;
}

.valueLabel {
    color: $grey50;
    font-size: 0.775rem;
    font-weight: $fontWeightBold;
    text-align: center;
    vertical-align: middle;
    max-width: 95px;
    margin-top: 0;
    margin-left: 1rem;
    margin-bottom: 0;
}

.green {
    color: $green;
}

.blue {
    color: $blue;
}
