@import "src/styles/variables";

.container {
    cursor: pointer;
    margin: 0 -10px;

    &:hover {
        background-color: $grey10;
    }
}

.selected {
    color: $blue;
}

.padding {
    padding: 5px 10px;
}
