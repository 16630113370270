@import "src/styles/variables";

.container {
    border: 1px solid $grey10;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.userParentContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    gap: 10px;
}

.userParent {
    flex-grow: 1;
    border-bottom: 1px solid $grey10;
    padding: 4px 8px;
}

.removeUserParent {
    flex-shrink: 0;
}

.rolesContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}
